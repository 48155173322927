import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { MarketingDataEncoded, VerificationId } from 'entities/marketing/marketing';

type Params = {
  preferredStoreId: number;
  marketingData: MarketingDataEncoded; // Base64 string;
  phoneNumber: string;
};
type Data = {
  id: VerificationId;
  timeout: number;
  nextTryIn: number;
};

const NAME = '/Marketing/VerifyPhoneNumber';
export const VerifyPhoneNumberEndpoint = createModernEndpoint<Params, Data>(NAME);

export default VerifyPhoneNumberEndpoint;
