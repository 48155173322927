import { useRouterQuery } from 'common/router';
import { decodeMarketingData, MarketingDataEncoded } from 'entities/marketing/marketing';

const MD_QUERY_KEY = 'notificationsData';
const MS_QUERY_KEY = 'source';

const useMarketingData = () => {
  const query = useRouterQuery();

  const marketingDataString = query[MD_QUERY_KEY] as MarketingDataEncoded | undefined;
  const sourceDataString = query[MS_QUERY_KEY] as MarketingDataEncoded | undefined;

  let marketingData;
  let queryKey;

  if (marketingDataString) {
    marketingData = decodeMarketingData(marketingDataString);
    queryKey = MD_QUERY_KEY;
  } else if (sourceDataString) {
    marketingData = decodeMarketingData(sourceDataString);
    queryKey = MS_QUERY_KEY;
  }

  return marketingData
    ? {
        ...marketingData,
        queryKey,
        raw: marketingDataString || sourceDataString,
      }
    : undefined;
};

export { useMarketingData, MS_QUERY_KEY };
