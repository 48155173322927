import Modal from '@swe/shared/ui-kit/components/modal';

import SectionHeading from '@swe/shared/ui-kit/components/section-heading';

import { useCallback, useRef } from 'react';

import { MS_QUERY_KEY } from './use-marketing-data';

import { useStoreConfig } from 'common/providers/config';
import { useRouterNavigate } from 'common/router';
import { Routes } from 'common/router/constants';
import { CodeVerificationForm } from 'domains/marketing/components/code-verification-form';
import { useMarketingSubscription } from 'domains/marketing/containers/marketing-subscription/use-marketing-subscription';
import { SubscriptionForm } from 'domains/marketing/containers/subscription-form';
import { SubscriptionStoreForm } from 'domains/marketing/containers/subscriptions-store-form';

const MarketingSubscription = () => {
  const navigate = useRouterNavigate();
  const { notificationAgreement, minimalCustomerAge } = useStoreConfig();

  const selectedUrl = useRef('');
  const onSuccess = useCallback(
    (isEmailSent?: boolean) => {
      const query = { url: selectedUrl.current };
      const destination = isEmailSent ? Routes.MarketingSubscriptionEmailCheck : Routes.MarketingSubscriptionSuccess;

      return navigate({
        pathname: destination,
        query,
      });
    },
    [navigate],
  );

  const onNotFound = useCallback(() => navigate(Routes.NotFound), [navigate]);

  const {
    marketingData,
    phoneNumber,
    isVerificationVisible,
    cancelVerification,
    resendVerificationCode,
    submitMarketingForm,
    submitMarketingQrForm,
    submitVerificationForm,
  } = useMarketingSubscription({ onSuccess });

  const onChange = useCallback((url: string) => {
    selectedUrl.current = url;
  }, []);

  if (!marketingData) return null;

  const { Email } = marketingData.Data;
  const formElement =
    // TODO move to useMarketingData
    marketingData.queryKey === MS_QUERY_KEY ? (
      // TODO remove component duplication
      <SubscriptionStoreForm
        minimalCustomerAge={minimalCustomerAge}
        notificationAgreement={notificationAgreement}
        marketingData={marketingData.raw}
        onSubmit={submitMarketingQrForm}
        onChange={onChange}
        onNotFound={onNotFound}
      />
    ) : (
      <SubscriptionForm
        minimalCustomerAge={minimalCustomerAge}
        notificationAgreement={notificationAgreement}
        marketingData={marketingData.raw}
        email={Email}
        onSubmit={submitMarketingForm}
        onChange={onChange}
      />
    );

  return (
    <div>
      <SectionHeading size="xl">Marketing subscription</SectionHeading>
      {formElement}
      <Modal
        ariaLabel="verificationModal"
        visible={isVerificationVisible}
        onClose={cancelVerification}
        showCloseButton={false}
      >
        <CodeVerificationForm
          phoneNumber={phoneNumber!}
          onSubmit={submitVerificationForm}
          onResendCode={resendVerificationCode}
          onCancel={cancelVerification}
        />
      </Modal>
    </div>
  );
};

export { MarketingSubscription };
