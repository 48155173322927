import { formatDate } from '@swe/shared/utils/date';

import { GetStoreListEndpoint } from 'endpoints/marketing/get-store-list';
import { MarketingDataEncoded } from 'entities/marketing/marketing';

const usePreferredStores = (marketingData: MarketingDataEncoded) => {
  const { data, isLoading } = GetStoreListEndpoint.useRequest({ marketingData });

  const dateOfBirth = data?.data?.dateOfBirth;

  return {
    list: data?.stores,
    userData: {
      ...data?.data,
      ...(dateOfBirth ? { dateOfBirth: formatDate(dateOfBirth, 'yyyy-MM-dd') } : {}),
    },
    isLoading,
  };
};

export { usePreferredStores };
