import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { MarketingDataEncoded, PreferredStore } from 'entities/marketing/marketing';

type Params = {
  marketingData: MarketingDataEncoded;
  clientIp?: string;
  clientLocation?: {
    lat: number;
    lng: number;
  };
};
type Data = {
  stores: PreferredStore[];
  data: {
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    dateOfBirth: DateISOString;
    isDateOfBirthRequired: boolean;
  };
};

const NAME = '/Marketing/GetStoreList';
export const GetStoreListEndpoint = createModernEndpoint<Params, Data>(NAME);
