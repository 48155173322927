import { useToggleable } from '@swe/shared/hooks/use-toggleable';

import { useTimeoutFor } from '@swe/shared/ui-kit/components/action-with-countdown';

import { useCallback, useRef } from 'react';

import { CodeVerificationFormValues } from 'domains/marketing/components/code-verification-form';
import { CONFIRM_PHONE_FORM_NAME } from 'domains/marketing/components/code-verification-form/config';
import { useMarketingData } from 'domains/marketing/containers/marketing-subscription/use-marketing-data';
import {
  MarketingSubscriptionFormValues,
  MarketingSubscriptionStoreOnSubmit,
} from 'domains/marketing/containers/subscription-form';
import ResendVerificationCodeEndpoint, {
  ResendVerificationCodeError,
} from 'endpoints/marketing/resend-verification-code';
import SubmitQrCodeSubscriptionEndpoint from 'endpoints/marketing/submit-qr-code-subscription';
import SubmitSubscriptionEndpoint from 'endpoints/marketing/submit-subscription';
import VerifyPhoneNumberEndpoint from 'endpoints/marketing/verify-phone-number';
import { MarketingQrSubscriptionForm, MarketingSubscriptionForm } from 'entities/marketing/marketing';

type UseMarketingSubscriptionOptions = {
  onSuccess?: (isEmailSent?: boolean) => any;
};

const useMarketingSubscription = ({ onSuccess }: UseMarketingSubscriptionOptions) => {
  const marketingData = useMarketingData();
  const filledForm = useRef<MarketingSubscriptionForm>({} as MarketingSubscriptionForm);
  const [isVerificationVisible, showVerification, closeVerification] = useToggleable();

  const submitSubscription = useCallback(
    async (form: MarketingSubscriptionForm) => {
      try {
        await SubmitSubscriptionEndpoint.request(form);
        onSuccess?.();
      } catch (e) {
        console.error(e);
      }
    },
    [onSuccess],
  );

  const submitSubscriptionQR = useCallback(
    async (form: MarketingQrSubscriptionForm) => {
      try {
        const result = await SubmitQrCodeSubscriptionEndpoint.request(form);
        onSuccess?.(result && result?.emailSent);
      } catch (e) {
        console.error(e);
      }
    },
    [onSuccess],
  );

  const [, setConfirmPhoneTimeout] = useTimeoutFor(CONFIRM_PHONE_FORM_NAME);
  const verifyPhoneNumber = useCallback(
    async (form: MarketingSubscriptionFormValues) => {
      filledForm.current = {
        preferredStoreId: form.preferredStore,
        firstName: form.firstName,
        lastName: form.lastName,
        email: form.email,
        phoneNumber: form.phoneNumber,
        marketingData: marketingData!.raw,
        dateOfBirth: form.dateOfBirth,
      };

      try {
        const { id, nextTryIn } = await VerifyPhoneNumberEndpoint.request({
          marketingData: marketingData!.raw,
          preferredStoreId: form.preferredStore,
          phoneNumber: form.phoneNumber!,
        });
        filledForm.current.verificationId = id;
        if (form.token) {
          filledForm.current.token = form.token;
        }
        setConfirmPhoneTimeout(nextTryIn);
        showVerification();
      } catch (e) {
        console.error(e);
      }
    },
    [marketingData, setConfirmPhoneTimeout, showVerification],
  );
  const submitVerificationForm = useCallback(
    async (form: CodeVerificationFormValues) => {
      try {
        if (!filledForm.current.token) {
          await submitSubscription({
            ...filledForm.current,
            verificationCode: form.code,
          });
        } else {
          await submitSubscriptionQR({
            email: filledForm.current.email,
            firstName: filledForm.current.firstName,
            lastName: filledForm.current.lastName,
            token: filledForm.current.token,
            phone: filledForm.current.phoneNumber,
            storeId: filledForm.current.preferredStoreId,
            dateOfBirth: filledForm.current.dateOfBirth,
            confirmation: {
              id: filledForm.current.verificationId,
              number: form.code,
            },
          });
        }

        closeVerification();
      } catch (e) {
        console.error(e);
      }
    },
    [closeVerification, submitSubscription, submitSubscriptionQR],
  );

  const resendVerificationCode = useCallback(async () => {
    try {
      const { nextTryIn } = await ResendVerificationCodeEndpoint.request({
        marketingData: marketingData!.raw,
        preferredStoreId: filledForm.current.preferredStoreId,
        verificationId: filledForm.current.verificationId!,
        phoneNumber: filledForm.current.phoneNumber!,
      });

      return nextTryIn;
    } catch (e) {
      return (e as ResendVerificationCodeError).details?.NextTryInSeconds ?? 0;
    }
  }, [marketingData]);
  const cancelVerification = useCallback(() => {
    closeVerification();
    filledForm.current.verificationId = undefined;
  }, [closeVerification]);

  const submitMarketingForm = useCallback(
    async (form: MarketingSubscriptionFormValues) => {
      if (form.phoneNumber) {
        void verifyPhoneNumber(form);
        return;
      }

      return submitSubscription({
        preferredStoreId: form.preferredStore,
        firstName: form.firstName,
        lastName: form.lastName,
        email: form.email,
        marketingData: marketingData!.raw,
        dateOfBirth: form.dateOfBirth,
      });
    },
    [marketingData, submitSubscription, verifyPhoneNumber],
  );

  const submitMarketingQrForm = useCallback(
    async (form: MarketingSubscriptionStoreOnSubmit) => {
      if (form.phoneNumber) {
        void verifyPhoneNumber(form);
        return;
      }

      return submitSubscriptionQR({
        token: form.token,
        firstName: form.firstName,
        lastName: form.lastName,
        email: form.email,
        phone: form.phoneNumber,
        storeId: form.preferredStore,
        dateOfBirth: form.dateOfBirth,
      });
    },
    [submitSubscriptionQR, verifyPhoneNumber],
  );

  return {
    marketingData,
    phoneNumber: filledForm.current?.phoneNumber,
    isVerificationVisible,
    submitMarketingForm,
    submitMarketingQrForm,
    submitVerificationForm,
    resendVerificationCode,
    cancelVerification,
  };
};

export type { UseMarketingSubscriptionOptions };
export { useMarketingSubscription };
