import { isSSR } from '@swe/shared/utils/environment';

type MarketingDataEncoded = string; // Base64 encoded string;
type MarketingDataDecoded = {
  email?: string;
};
type VerificationId = string;

type PreferredStore = {
  id: number;
  name: string;
  distance?: number; // m.u.: mile
  storeUrl: string;
};

type MarketingSubscriptionForm = {
  marketingData: MarketingDataEncoded;
  preferredStoreId: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  verificationId?: VerificationId;
  verificationCode?: string;
  token?: string;
  dateOfBirth?: DateISOString;
};

type MarketingQrSubscriptionForm = {
  token: string;
  firstName: string;
  lastName: string;
  email: string;
  storeId?: number;
  phone?: string;
  confirmation?: {
    id?: string;
    number?: string;
  };
  dateOfBirth?: DateISOString;
};

type MarketingVerifyQrCodeSubscription = {
  signedData: string;
};

const decodeMarketingData = (data: MarketingDataEncoded) => {
  try {
    if (isSSR) return JSON.parse(Buffer.from(data, 'base64').toString());

    return JSON.parse(window.atob(data));
  } catch (e) {
    return undefined;
  }
};

export type {
  MarketingDataEncoded,
  MarketingDataDecoded,
  MarketingSubscriptionForm,
  PreferredStore,
  VerificationId,
  MarketingQrSubscriptionForm,
  MarketingVerifyQrCodeSubscription,
};
export { decodeMarketingData };
