import { BASE_BIRTHDAY_SCHEMA } from '@swe/shared/tools/validation/constants';
import { yup } from '@swe/shared/ui-kit/components/form';

import { MarketingSubscriptionFormValues } from './interface';

type MarketingSubscriptionValidationSchema = {
  isPhoneNumberRequired?: boolean;
};

const initialValues: Partial<MarketingSubscriptionFormValues> = {
  preferredStore: undefined,
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  marketingConsent: false,
  dateOfBirth: '',
};

const createInitialValues = (iv: Partial<MarketingSubscriptionFormValues>) => ({
  ...initialValues,
  ...iv,
});

const validationSchema = yup.object({
  preferredStore: yup.number().required('You should select the store'),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().required('Email is required'),
  phoneNumber: yup.string(),
  dateOfBirth: BASE_BIRTHDAY_SCHEMA(),
  marketingConsent: yup.boolean().isTrue('Consent is required'),
});

const validationStoreSchema = ({ isPhoneNumberRequired = false }: MarketingSubscriptionValidationSchema) => {
  const phoneNumberValidation = isPhoneNumberRequired
    ? yup.string().test('valid-or-empty', 'Please enter a valid phone number', (value) => {
        if (value === undefined || value === '') return true;
        return value.length >= 12;
      })
    : yup.string();

  return yup.object({
    preferredStore: yup.number(),
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
    phoneNumber: phoneNumberValidation,
    dateOfBirth: BASE_BIRTHDAY_SCHEMA(),
    marketingConsent: yup.boolean().isTrue('Consent is required'),
  });
};

const consentText =
  'I consent to receive informational and promotional messages at the mobile phone number and/or email address I have provided. I understand by consenting, you, your affiliates, or your agents may send me SMS messages and/or emails (consistent with state law and regulation) concerning orders, sales, product availability, and industry news and insights. You may opt-out of receiving promotional messages at any time.';

export { createInitialValues, initialValues, validationSchema, consentText, validationStoreSchema };
