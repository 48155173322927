import createModernEndpoint, { APIErrorExtended } from '@swe/shared/network/endpoint-factories/modern';

import { ApiErrorCode } from '@swe/shared/network/endpoint-factories/modern/types';

import { MarketingDataEncoded, VerificationId } from 'entities/marketing/marketing';

type Params = {
  marketingData: MarketingDataEncoded; // Base64 string;
  verificationId: VerificationId;
  preferredStoreId: number;
  phoneNumber: string;
};
type Data = {
  verificationId: number | string;
  nextTryIn: number;
};

type ResendVerificationCodeError = APIErrorExtended<
  ApiErrorCode.BadRequest,
  {
    NextTryInSeconds: number;
  }
>;

const NAME = '/Marketing/ResendVerificationCode';
export const ResendVerificationCodeEndpoint = createModernEndpoint<Params, Data, ResendVerificationCodeError>(NAME);

export default ResendVerificationCodeEndpoint;

export type { ResendVerificationCodeError };
