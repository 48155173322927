import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { MarketingQrSubscriptionForm } from 'entities/marketing/marketing';

type Params = MarketingQrSubscriptionForm;
type Data =
  | {
      customerId?: number;
      customerEmailSubscribed?: boolean;
      cistomerSmsSubscribed?: boolean;
      emailSent?: boolean;
      smsSent?: boolean;
    }
  | undefined;

const NAME = '/Marketing/SubmitQrCodeSubscription';
export const SubmitQrCodeSubscriptionEndpoint = createModernEndpoint<Params, Data>(NAME);

export default SubmitQrCodeSubscriptionEndpoint;
export type { Data };
