import { ApplicationPage } from 'app/types';
import { MarketingSubscription } from 'domains/marketing/containers/marketing-subscription';

const MarketingSubscriptionPage: ApplicationPage = () => {
  return <MarketingSubscription />;
};

MarketingSubscriptionPage.getMainLayoutProps = () => ({
  header: null,
  subheader: null,
  footer: null,
  centered: true,
  size: 'md',
});

export default MarketingSubscriptionPage;
