import { PreferredStore } from 'entities/marketing/marketing';

// const formatDistance = (distance: number) => distance.toFixed(0);
//
// const withMiles = (storeName: string, distance?: number) => {
//   if (!distance) return storeName;
//
//   return `${storeName} (${formatDistance(distance)}mi away)`;
// };

const mapStoreToOption = (store: PreferredStore) => ({
  value: store.id,
  label: store.name,
});

export { mapStoreToOption };
