import createModernEndpoint, { APIErrorExtended } from '@swe/shared/network/endpoint-factories/modern';

import { MarketingDataEncoded, PreferredStore } from 'entities/marketing/marketing';

type Params = {
  source: MarketingDataEncoded;
};
type Data = {
  store: PreferredStore;
  isPhoneNumberRequired: boolean;
  isDateOfBirthRequired: boolean;
  token: string;
};
type Error = APIErrorExtended;

const NAME = '/Marketing/AuthByQrCode';
export const AuthByQrCodeEndpoint = createModernEndpoint<Params, Data, Error>(NAME);
