import { MIN_DATE_OF_BIRTH, TODAY } from '@swe/shared/tools/validation/constants';
import Form from '@swe/shared/ui-kit/components/form';

import { BuilderContext } from '@swe/shared/ui-kit/components/form/core/builder';
import Grid from '@swe/shared/ui-kit/components/grid';

import Stack from '@swe/shared/ui-kit/components/stack';

import { formatDate } from '@swe/shared/utils/date';

import { useRef, useEffect, useCallback } from 'react';

import { consentText, createInitialValues, validationSchema } from 'domains/marketing/containers/config';
import { MarketingSubscriptionOnSubmit } from 'domains/marketing/containers/interface';
import { mapStoreToOption } from 'domains/marketing/containers/utils';
import { usePreferredStores } from 'domains/marketing/use-cases/use-prefered-stores';
import { MarketingDataEncoded } from 'entities/marketing/marketing';

type SubscriptionFormProps = {
  marketingData: MarketingDataEncoded;
  email: string;
  onSubmit: (values: MarketingSubscriptionOnSubmit) => void;
  onChange: (url: string) => void;
  notificationAgreement?: string;
  minimalCustomerAge: number;
};

const SubscriptionForm = ({
  marketingData,
  minimalCustomerAge,
  email,
  notificationAgreement,
  onSubmit,
  onChange,
}: SubscriptionFormProps) => {
  const { list, isLoading, userData } = usePreferredStores(marketingData);
  const { isDateOfBirthRequired } = userData ?? {};

  const formRef = useRef<BuilderContext<MarketingSubscriptionOnSubmit>>(null);

  const options = (list || []).map(mapStoreToOption);

  const _onChange = useCallback(
    (_id: number) => {
      const store = list?.find(({ id }) => id === _id);
      if (store && store.storeUrl) {
        onChange(store.storeUrl);
      }
    },
    [list, onChange],
  );

  const onSubmitHandler = useCallback(
    (entity: MarketingSubscriptionOnSubmit) => {
      const { dateOfBirth } = entity;
      onSubmit({
        ...entity,
        ...(dateOfBirth ? { dateOfBirth: formatDate(dateOfBirth, 'yyyy-MM-dd') } : {}),
      });
    },
    [onSubmit],
  );

  useEffect(() => {
    if (options.length === 1) {
      formRef.current?.setValue('preferredStore', options[0].value);
      _onChange(options[0].value);
    }
    if (userData) {
      formRef.current?.setValue('firstName', userData?.firstName || '');
      formRef.current?.setValue('lastName', userData?.lastName || '');
      formRef.current?.setValue('email', userData?.email || '');
      formRef.current?.setValue('phoneNumber', userData?.phone || '');
    }
  }, [_onChange, options, userData]);

  if (isLoading) return null;

  return (
    <Form.Builder
      name="marketingSubscriptionForm"
      initialValues={createInitialValues({
        email,
        dateOfBirth: userData?.dateOfBirth ?? '',
      })}
      validationSchema={validationSchema}
      validationContext={{ minimalCustomerAge }}
      autofocus={false}
      onSubmit={onSubmitHandler}
      ref={formRef}
    >
      <Stack>
        <div>
          <Form.Select
            name="preferredStore"
            label="Preferred store"
            placeholder="Select store"
            options={options}
            disabled={isLoading || options.length === 1}
            onChange={_onChange}
          />
          <Grid.Row>
            <Grid.Cell cols={6}>
              <Form.Input
                label="First name"
                name="firstName"
                placeholder="First name"
                isClearable
              />
            </Grid.Cell>
            <Grid.Cell cols={6}>
              <Form.Input
                label="Last name"
                name="lastName"
                placeholder="Last name"
                isClearable
              />
            </Grid.Cell>
          </Grid.Row>
          <Grid.Row>
            <Grid.Cell cols={6}>
              <Form.Input
                label="Email"
                name="email"
                placeholder="example@gmail.com"
                isClearable
                disabled
              />
            </Grid.Cell>
            <Grid.Cell cols={6}>
              <Form.Input
                label="Phone number"
                name="phoneNumber"
                placeholder="Contact phone number"
                maskPreset="phone_us"
                isClearable
              />
            </Grid.Cell>
          </Grid.Row>
          {isDateOfBirthRequired && (
            <Grid.Row hAlign="end">
              <Grid.Cell cols={6}>
                <Form.InputDate
                  label="Date of birth"
                  name="dateOfBirth"
                  placeholder="Enter date of birth"
                  disabled={!!userData?.dateOfBirth}
                  isClearable={false}
                  max={TODAY}
                  min={MIN_DATE_OF_BIRTH}
                  initialScreen="years"
                />
              </Grid.Cell>
            </Grid.Row>
          )}
          <Form.Checkbox
            name="marketingConsent"
            label={notificationAgreement || consentText}
            truncatedLines={2}
          />
        </div>
        <Form.SubmitButton block>Confirm</Form.SubmitButton>
      </Stack>
    </Form.Builder>
  );
};

export * from '../interface';
export type { SubscriptionFormProps };
export { SubscriptionForm };
