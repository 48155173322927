import { AuthByQrCodeEndpoint } from 'endpoints/marketing/auth-by-qr-code';

const useAuthByQrCode = (source: string) => {
  const { data, isLoading, error } = AuthByQrCodeEndpoint.useRequest({ source }, { notifyWithSnackbar: false });
  return {
    store: data?.store,
    isPhoneNumberRequired: data?.isPhoneNumberRequired,
    isDateOfBirthRequired: data?.isDateOfBirthRequired,
    token: data?.token,
    error,
    isLoading,
  };
};

export { useAuthByQrCode };
