import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { MarketingSubscriptionForm } from 'entities/marketing/marketing';

type Params = MarketingSubscriptionForm;
type Data = undefined;

const NAME = '/Marketing/SubmitSubscription';
export const SubmitSubscriptionEndpoint = createModernEndpoint<Params, Data>(NAME);

export default SubmitSubscriptionEndpoint;
