import { SnackbarService } from '@swe/shared/providers/snackbar';
import { MIN_DATE_OF_BIRTH, TODAY } from '@swe/shared/tools/validation/constants';
import Form from '@swe/shared/ui-kit/components/form';

import { BuilderContext } from '@swe/shared/ui-kit/components/form/core/builder';
import Grid from '@swe/shared/ui-kit/components/grid';

import Loader from '@swe/shared/ui-kit/components/loader';
import Stack from '@swe/shared/ui-kit/components/stack';

import { formatDate } from '@swe/shared/utils/date';

import { useRef, useEffect, useMemo, useCallback } from 'react';

import { consentText, initialValues, validationStoreSchema } from 'domains/marketing/containers/config';
import { MarketingSubscriptionStoreOnSubmit } from 'domains/marketing/containers/interface';

import { mapStoreToOption } from 'domains/marketing/containers/utils';
import { useAuthByQrCode } from 'domains/marketing/use-cases/use-auth-by-qr-code';
import { MarketingDataEncoded } from 'entities/marketing/marketing';

type SubscriptionStoreFormProps = {
  marketingData: MarketingDataEncoded;
  onSubmit: (values: MarketingSubscriptionStoreOnSubmit) => void;
  onChange: (url: string) => void;
  onNotFound: () => Promise<boolean>;
  notificationAgreement?: string;
  minimalCustomerAge: number;
};

const SubscriptionStoreForm = ({
  minimalCustomerAge,
  notificationAgreement,
  marketingData,
  onSubmit,
  onChange,
  onNotFound,
}: SubscriptionStoreFormProps) => {
  const { store, isLoading, error, isPhoneNumberRequired, isDateOfBirthRequired, token } =
    useAuthByQrCode(marketingData);
  const formRef = useRef<BuilderContext<MarketingSubscriptionStoreOnSubmit>>(null);
  const optionStore = store && mapStoreToOption(store);

  useEffect(() => {
    if (error) {
      SnackbarService.push({
        type: 'danger',
        message: error.message,
      });
      void onNotFound();
    }
  }, [error, onNotFound]);

  const onSubmitHandler = useCallback(
    (entity: MarketingSubscriptionStoreOnSubmit) => {
      const { dateOfBirth } = entity;
      onSubmit({
        ...entity,
        ...(dateOfBirth ? { dateOfBirth: formatDate(dateOfBirth, 'yyyy-MM-dd') } : {}),
      });
    },
    [onSubmit],
  );

  useEffect(() => {
    if (!optionStore || !store || !token) return;
    formRef.current?.setValue('preferredStore', optionStore.value);
    formRef.current?.setValue('prefilledStore', optionStore.label);
    formRef.current?.setValue('token', token);
    onChange(store.storeUrl);
  }, [onChange, optionStore, store, token]);

  const userEmailPhoneRowElement = useMemo(() => {
    const phoneNumberCellElement = isPhoneNumberRequired ? (
      <Grid.Cell cols={6}>
        <Form.Input
          label="Phone number"
          name="phoneNumber"
          placeholder="Contact phone number"
          maskPreset="phone_us"
          isClearable
        />
      </Grid.Cell>
    ) : null;

    return (
      <Grid.Row>
        <Grid.Cell cols={isPhoneNumberRequired ? 6 : 12}>
          <Form.Input
            label="Email"
            name="email"
            placeholder="example@gmail.com"
            isClearable
          />
        </Grid.Cell>
        {phoneNumberCellElement}
      </Grid.Row>
    );
  }, [isPhoneNumberRequired]);

  if (isLoading)
    return (
      <Loader
        center
        centered
      />
    );

  if (error) {
    return null;
  }

  return (
    <Form.Builder
      name="marketingSubscriptionStoreForm"
      initialValues={initialValues}
      validationSchema={validationStoreSchema({ isPhoneNumberRequired })}
      validationContext={{ minimalCustomerAge }}
      autofocus={false}
      onSubmit={onSubmitHandler}
      ref={formRef}
    >
      <Stack>
        <div>
          <Form.Input
            name="prefilledStore"
            label="Preferred store"
            disabled
          />
          <Grid.Row>
            <Grid.Cell cols={6}>
              <Form.Input
                label="First name"
                name="firstName"
                placeholder="First name"
                isClearable
              />
            </Grid.Cell>
            <Grid.Cell cols={6}>
              <Form.Input
                label="Last name"
                name="lastName"
                placeholder="Last name"
                isClearable
              />
            </Grid.Cell>
          </Grid.Row>
          {userEmailPhoneRowElement}
          {isDateOfBirthRequired && (
            <Grid.Row hAlign="end">
              <Grid.Cell cols={6}>
                <Form.InputDate
                  label="Date of birth"
                  name="dateOfBirth"
                  placeholder="Enter date of birth"
                  required
                  max={TODAY}
                  min={MIN_DATE_OF_BIRTH}
                  initialScreen="years"
                />
              </Grid.Cell>
            </Grid.Row>
          )}
          <Form.Checkbox
            name="marketingConsent"
            label={notificationAgreement || consentText}
            truncatedLines={2}
          />
        </div>
        <Form.SubmitButton block>Confirm</Form.SubmitButton>
      </Stack>
    </Form.Builder>
  );
};

export * from '../interface';
export type { SubscriptionStoreFormProps };
export { SubscriptionStoreForm };
